import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { SidebarCloseIcon } from "mixins/svgIcons";

import "./styles.scss";
import { BeatLoader } from "react-spinners";
import { getCandidateAnswers, getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import { connect } from "react-redux";
import { store } from "store";
import { setSidebarOpen } from "store/modules/jobs/actions";
import { EMPTY, FAILED, NOT_AVAILABLE } from "configs/jobs/constants";
import { getToken } from "store/modules/auth/selectors";
import useTranscriptionStore from "store/transcriptionStore.ts";
import { withRouter } from "react-router-dom";
import CustomTooltip from "components/Common/CustomTooltip/CustomTooltip.tsx";
import WilloIntelligence from "assets/images/candidate/willo-intelligence.png";
import CandidateTranscription from "./CandidateTranscription";
import NoTranscriptionsHistory from "./NoTranscriptionsHistory.tsx";


const CandidateSidebarTranscriptions = ({
  t,
  isJobCandidateLoading,
  token,
  match: {
    params: { userId }
  },
  candidateAnswers,
  currentQuestionAnswer
}) => {
  const { fetch, transcriptions } = useTranscriptionStore();
  const currentSelectedIndex = candidateAnswers?.findIndex(candidateAnswer =>
    candidateAnswer.key === currentQuestionAnswer?.key);

  const transcriptionStatus =
  transcriptions?.[currentSelectedIndex]?.transcript_text_generation_status;

  const isEmptyTranscriptions = transcriptions?.length === 0 ||
  [EMPTY, NOT_AVAILABLE, FAILED].includes(transcriptionStatus) || !transcriptionStatus;

  useEffect(() => {
    if (userId && token) {
      fetch(userId, token);
    }
  }, [fetch, token, userId]);

  return (
    <div className={`flex flex-col ${isEmptyTranscriptions ? "bg-black-5" : "bg-white"} flex-1`}>
      <div className="transcription__sidebar-wrapper flex flex-col flex-1">
        <div
          className="transcription__sidebar-container transcripts flex-1"
          style={{
            padding: "12px 11px",
            paddingBottom: 0
          }}
        >
          <div
            className="candidate__sidebar-comments__header flex w-full items-center justify-between"
            style={{
              paddingLeft: "10px",
              paddingRight: "10px"
            }}
          >
            <div className="flex flex-row items-center gap-4">
              <CustomTooltip
                content={
                  <div className="font-normal">
                    <span className="text-main-purple font-poppins">{t("willoIntelligence.0")}</span>
                    {t("willoIntelligence.1")}
                  </div>
                }
              >
                <img
                  src={WilloIntelligence}
                  alt="Willo Intelligence"
                  className="w-5 h-5 cursor-help"
                />
              </CustomTooltip>
              <span className="text-base text-black-90">{t("transcriptions.title")}</span>
            </div>

            <SidebarCloseIcon
              style={{ pointerEvents: "all", cursor: "pointer" }}
              onClick={() => store.dispatch(setSidebarOpen(false))}
            />
          </div>
          {
            isJobCandidateLoading ? (
              <div className="flex flex-col justify-center items-center flex-1">
                <BeatLoader color="#5A2AF1" size={10} />
                <span className="loading-text">{t("button.loading")}</span>
              </div>
            ) : (
              <>
                {
                  isEmptyTranscriptions
                    ? <NoTranscriptionsHistory /> :
                    <CandidateTranscription />
                }
              </>
            )
          }
        </div>
      </div>

      <>
        <div className="flex flex-col sticky bottom-0">
          <div className="h-6 bg-gradient-to-t from-white to-transparent" />
          {/* @TODO: Add download transcript */}
        </div>
      </>
    </div>
  );
};


const mapStateToProps = state => ({
  isJobCandidateLoading: getIsJobCandidateLoading(state),
  token: getToken(state),
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer,
  candidateAnswers: getCandidateAnswers(state)

});

export default withRouter(
  connect(mapStateToProps)(withTranslation()(CandidateSidebarTranscriptions))
);
