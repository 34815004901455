import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import { ALL_IDENTITY_VERIFICATION_TYPES } from "configs/jobs/constants";
import useWindowResize from "hooks/useWindowResize.tsx";

const CandidateAnswerTitle = ({
  t,
  question,
  isJobCandidateLoading,
  answerKey
}) => {
  const { isMobileAndTabletScreen } = useWindowResize();

  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef(null);
  const divRef = useRef(null);

  const questionKey = useMemo(() => question?.key, [question]);

  const checkIsEllipsisActive = useCallback(e => {
    if (!e) return false;

    const c = e.cloneNode(true);
    c.style.webkitLineClamp = "inherit";
    c.style.maxHeight = "100%";

    divRef.current.appendChild(c);

    const truncated = c.offsetHeight > e.offsetHeight;

    if (c && typeof c.remove === "function") c.remove();

    return truncated;
  }, []);

  useEffect(() => {
    if (textRef.current) {
      setShowButton(checkIsEllipsisActive(textRef.current));
    }

    return () => {
      setIsExpanded(false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionKey, answerKey]);

  const toggleExpand = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <div
      className="candidateanswer__section__header-title__text"
      key={`title-${questionKey}`}
      ref={divRef}
    >
      <SkeletonWrapper
        skeletonProps={{
          count: 1,
          height: 20,
          width: isMobileAndTabletScreen ? "100%" : "30rem",
          enableAnimation: true
        }}
        isLoading={isJobCandidateLoading}
      >
        <div
          className={`expandable-text__content ${isExpanded ? "expanded" : "truncated"}`}
          ref={textRef}
          key={question?.key}
        >
          { ALL_IDENTITY_VERIFICATION_TYPES.includes(question?.answer_type)
            ? t(`job.candidate.overview.idv.answer.thumbnail.${question?.answer_type}`)
            : ReactHtmlParser(question?.html_text) || question?.text }
        </div>
      </SkeletonWrapper>


      {showButton && !isJobCandidateLoading && (
        <button
          className="expandable-text__toggle button__without-styles w-full flex justify-end"
          onClick={toggleExpand}
          type="button"
          aria-label={isExpanded ? "See less" : "See more"}
        >
          {isExpanded ? "See less" : "See more"}
        </button>
      )}
    </div>
  );
};

export default withTranslation()(CandidateAnswerTitle);
